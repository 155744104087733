.feature-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    gap: 30px;

    h2 {
        color: #0D9B6E;
        font-size: 48px;
        font-weight: 700;
    }

    .bottom-section {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 60px;
        button {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: #0D9B6E;
            border: 2px solid #0D9B6E;
            border-radius: 50px;
            padding: 12px 60px;
            color: white;
            font-weight: 500;
            cursor: pointer;
            transition: all .3s ease-in;
            font-size: 16px;

            &:hover {
                background-color: white;
                color: #0D9B6E;
                border: 2px solid #0D9B6E;
            }
        }
        p{
            color: #0D9B6E;
            font-weight: 600;
            font-size: 32px;
            width: 32%;
            span{
                font-size: 48px;
            }
        }
    }
}

@media screen and (width <=440px){
    .feature-section{
        h2{
            font-size: 40px;
        }
        .bottom-section{
            flex-direction: column-reverse;
            align-items: center;

            p{
                width: 76%;
            }
        }

    }
}