header {
    display: flex;
    align-items: center;
    background-color: #0D9B6E;
    color: #fff;
    padding: 10px 40px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 999;

    .left {
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
            cursor: pointer;
            font-size: 24px;

        }

        .mainLogo {
            width: 50px;
            cursor: pointer;
        }
    }

    .middle {

        nav {

            ul {
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 100px;

                li {
                    font-weight: 500;
                    font-size: 18px;
                    cursor: pointer;


                    &:hover {
                        scale: 1.1;
                    }

                }

            }


        }
    }

    .right {
        button {
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: white;
            border: none;
            border-radius: 50px;
            padding: 8px 30px;
            color: #0D9B6E;
            font-weight: 500;
            cursor: pointer;
            transition: all .3s ease-in;
            font-size: 16px;

            &:hover {
                background-color: #0D9B6E;
                color: white;
                border: 2px solid white;
                scale: 1.01;
            }


        }
    }

    .ham,
    .menuNotOpen {
        display: none;

    }
}

.scrolled {

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (440px <=width <=770px) {

    header .middle nav ul {
        gap: 30px !important;
    }
    
    .link-wrapper{
        gap: 70px;
    }
    
    
    header .left h2 {
        font-size: 22px;
    }
    header .right button {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: white;
        border: none;
        border-radius: 50px;
        padding: 6px 20px;
    }

}

@media screen and (width <=440px) {

    header {

        .middle,
        .right {
            display: none;
        }

        .ham {
            display: block;
            width: 45px;
        }

        .menuOpen {
            position: fixed;
            width: 75%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: white;
            z-index: 9999;
            padding: 30px 25px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            box-shadow: rgba(0, 0, 0, 0.15) -7px 16px 4px 2px;
            transition: display 0.3s ease;
            align-items: flex-start;
            justify-content: flex-start;

            nav {
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    color: #0D9B6E;

                }
            }

            .top {
                display: flex;
                align-items: center;
                gap: 10px;

                h3 {
                    font-size: 21px;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: #0D9B6E;
                }

                .closeImg {
                    width: 30px;
                }
            }

        }


    }
}

@media screen and (width <=340px) {
    header {
        gap: 30px;
    }
}