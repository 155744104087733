footer {
    background-color: #D5FBE9;
    display: flex;
    justify-content: space-around;
    padding: 60px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        /* Height of the gradient border */
        background: linear-gradient(90deg, rgba(13, 155, 110, 1) 0%, rgba(227, 247, 238, 1) 50%, rgba(13, 155, 110, 1) 100%);
    }

    .footer-logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 50px;
            cursor: pointer;
        }

        h3 {
            color: #0D9B6E;
            font-weight: 700;
            font-size: 28px;
            cursor: pointer;
        }

        p {
            color: #0D9B6E;
        }
    }

    .link-wrapper {    
        display: flex;
        gap: 200px;
        flex-direction: row;
        align-items: center;

        .textLinks{
            display: flex;
            gap: 200px;
            
            .links {

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
    
                    .heading {
                        font-weight: 600;
                        font-size: 18px;
                    }
    
                    .inner-links {
                        font-size: 15px;
                        cursor: pointer;
    
                    }
                }
    
            }
        }
        

        .social-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 20px;

            .social-icons {
                display: flex;
                gap: 20px;
                align-items: center;

                img {
                    width: 30px;
                    cursor: pointer;
                }
            }

            .social-text {
                display: flex;
                align-items: center;
                gap: 15px;

                p {
                    font-size: 10px;
                    cursor: pointer;
                }
            }
        }
    }

}

@media screen and (770px <= width <=1030px){
    footer .link-wrapper {
        gap: 65px !important;
    } 
    
    footer .link-wrapper .textLinks {
        gap: 100px !important;
    }
   
}

@media screen and (440px <=width <=770px) {
    
    footer .link-wrapper .textLinks {
        gap: 200px !important;
    }
    footer .textLinks .links ul {
        gap: 18px;
    }

    footer {

        flex-direction: column;
        align-items: center
    }
    
    footer .link-wrapper {
        display: flex;
        gap: 65px !important;
        flex-direction: column;
    }
    
    footer .link-wrapper .social-wrapper .social-text {
        
        justify-content: center;
    }
    footer .link-wrapper .social-wrapper .social-icons {
        gap: 60px;
    }

    
}

@media screen and (width <=440px) {
    
    footer {
        flex-direction: column;
    }
    
    footer .link-wrapper {
        flex-direction: column;
        gap: 50px;
        
         .textLinks {
            gap: 60px;
        }
        
        .social-wrapper .social-icons {
            gap: 50px;
        }
        
       .social-wrapper .social-text {
            justify-content: center;
        }
    }
    
    
    
   
}

@media screen and (width <=340px) {
    footer .link-wrapper .textLinks {
        flex-direction: column;
    }
    footer .link-wrapper .textLinks .links ul {
        align-items: center;
    }
}