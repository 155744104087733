.reverse{
    flex-direction: row-reverse;
    .feature-text{
        align-items: flex-end;

        p{
            text-align: end;
        }
    }
}
.feature{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 70%;
   
    .feature-img{
        background-image: url('../../assets/images/feature1.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        padding: 50px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        

        img{
            width: 40px;
        }
    }

    .feature-text{
        display: flex;
        flex-direction: column;

        h3{
            color: #0D9B6E;
            font-size: 22px;
        }
        p{
            color: #6F7989;
            font-size: 16px;
            font-weight: 700;
            width: 80%;
        }
    }
}

@media screen and (770px <= width <=1030px){
    .feature-section .bottom-section p {
        font-size: 30px;
    }
    .feature-section .bottom-section p span {
        font-size: 44px;
    }
    .feature-section .bottom-section button {
        padding: 10px 34px;
    }
  
}
  
@media screen and (440px <=width <=770px) {
.feature {
    gap: 86px;
    width: 90%;
}

}
@media screen and (width <=440px){
    .feature-section{
        
        .feature{
            flex-direction: column;
            .feature-img{
                padding: 30px;
                img{
                    width: 30px;
                }
            }
            .feature-text{
                align-items: center;
                p, h3{
                    text-align: center;
                }
            }
        }
        .reverse{
            .feature-text{
                align-items: center;
                p, h3{
                    text-align: center;
                }
            }
        }

    }
}

@media screen and (width <=340px) {
    .feature-section .bottom-section p span {
        font-size: 42px;
    }
    .feature-section .bottom-section p {
        font-size: 26px;
    }
}