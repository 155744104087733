.Hero {
    // background-image: url('../../assets/images/blob.png');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(120vh - 70px);
    position: relative;
}

@keyframes moveCircular {
    0% {
        transform: rotate(0deg) translateX(100px) rotate(0deg);
    }

    25% {
        transform: rotate(90deg) translateX(100px) rotate(-90deg);
    }

    50% {
        transform: rotate(180deg) translateX(100px) rotate(-180deg);
    }

    75% {
        transform: rotate(270deg) translateX(100px) rotate(-270deg);
    }

    100% {
        transform: rotate(360deg) translateX(100px) rotate(-360deg);
    }
}

.Hero::before {
    content: "";
    background-image: url('../../assets/images/blob.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 800px;
    height: 600px;
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    animation: moveCircular 7s infinite linear;
    z-index: -1;
}


.blob {
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// background-image: url("../../assets/images/heroBack.jpg");
// background-position: center;
// background-repeat: no-repeat;
// background-size: cover;

.herodiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    width: 70%;
    background: rgba(255, 255, 255, 0.3);
    border: 2px solid #fff;
    border-radius: 10px;


    h1 {
        color: #0D9B6E;
        font-size: 48px;
        font-weight: 600;
        text-align: center;


        span {
            font-size: 72px;
        }
    }

    button {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #0D9B6E;
        border: 2px solid #0D9B6E;
        border-radius: 50px;
        padding: 8px 30px;
        color: white;
        font-weight: 500;
        cursor: pointer;
        transition: all .3s ease-in;
        font-size: 16px;

        &:hover {
            background-color: white;
            color: #0D9B6E;
            border: 2px solid #0D9B6E;
            scale: 1.01;
        }



        img {
            object-fit: contain;
            width: 11px;
        }

        span {
            font-size: 18px;
            font-weight: 500;

        }
    }
}

@media screen and (440px <=width <=770px) {
    .Hero::before {

        width: 400px !important;


    }
}

@media screen and (width <=440px) {
    .Hero {


        .herodiv {
            width: 85%;
            gap: 25px;

            h1 {

                font-size: 35px;

                span {
                    font-size: 48px;
                }
            }
        }
    }
    
    .Hero::before {
        width: 400px;
        height: 365px;
        top: 24%;
        left: 1%;
        bottom: 0;
        border-radius: 50px;
    }
}